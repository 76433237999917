import styles from './styles/card.module.scss';

const Card = ({
    children,
    className,
    customShadow,
    border
}: {
    children: React.ReactNode;
    className?: string;
    customShadow?: boolean;
    border?: boolean;
}) => {
    return (
        <div className={`${styles.card} ${className} ${border ? styles.border : ''} ${customShadow ? styles.custom_shadow : ''}`}>
            {children}
        </div>
    );
};

export default Card;
