import Link from 'next/link';
import styles from './styles/title.module.scss';
import { ReactNode } from 'react';
import { hexToRgba } from '@/services/utils/cmnHelper';
import { CountdownTimer, MixPanelBlockAction } from '../templates/layout/lazy-load';
import { v2_arrow_back } from '@/services/utils/svg-server';
import { ClientLogTest } from '@/services/utils/clientHelper';

interface Props {
    title: string | undefined;
    label?: string | undefined;
    link?: string | undefined;
    linkTopDisabled?: boolean;
    container?: boolean;
    container2?: boolean;
    bg?: string | any;
    subTitle?: SubTitle | any;
    extraContent?: ReactNode;
    startEndData?: any;
    bottomLink?: boolean;
    isClient?: boolean;
    data?: any;
}

interface SubTitle {
    title: string | any;
    color?: 'text_primary' | 'text_error' | string;
    colorCode?: any;
}

const Title: React.FC<Props> = ({
    title,
    label,
    link,
    linkTopDisabled,
    bottomLink,
    container,
    container2,
    subTitle,
    bg,
    extraContent,
    startEndData,
    isClient,
    data
}) => {
    const styleGenerate = `${bottomLink ? styles.bottom_link : ''} ${styles.title_wrap}`;
    return (
        <div
            style={{ color: subTitle?.colorCode }}
            className={`${container ? 'container' : ''} ${container2 ? 'container2' : ''} ${styleGenerate}`}
        >
            {title && !bottomLink && (
                <div className={styles.title}>
                    <span>{title || 'Not Found'}</span>
                    {link &&
                        !linkTopDisabled &&
                        (isClient ? (
                            <MixPanelBlockAction
                                id={data?.block_id}
                                name={data?.block}
                                extra={{
                                    link,
                                    scroll: false,
                                    label: label || <>see&nbsp;all</>,
                                    style: { color: bg ? bg : '' }
                                }}
                            />
                        ) : (
                            <Link scroll={false} href={`${link}` || '/'} style={{ color: bg ? bg : '' }}>
                                {label || <>see&nbsp;all</>}
                            </Link>
                        ))}
                    {extraContent}
                </div>
            )}
            {subTitle && !bottomLink && (
                <span style={{ color: subTitle?.colorCode }} className={styles[subTitle?.color ? subTitle?.color : '']}>
                    {startEndData ? (
                        <CountdownTimer startDate={startEndData?.block_start_date} endDate={startEndData?.block_end_date} />
                    ) : (
                        subTitle?.title
                    )}
                </span>
            )}
            {bottomLink &&
                link &&
                (isClient ? (
                    <MixPanelBlockAction
                        id={data?.block_id}
                        name={data?.block}
                        extra={{
                            link,
                            scroll: false,
                            label: label || <>see&nbsp;all</>,
                            style: { color: bg ? bg : '' }
                        }}
                    />
                ) : (
                    <Link
                        scroll={false}
                        href={`${link}` || '/'}
                        className={styles.bottom_link_btn}
                        style={{
                            background: hexToRgba(`${bg ? bg : '#fff'}`, 0.3)
                        }}
                    >
                        <span>
                            {label || <>See&nbp;All</>}
                            <b>&nbsp;&quot;{title}&quot;</b>
                        </span>
                        <span>{v2_arrow_back}</span>
                    </Link>
                ))}
        </div>
    );
};

export default Title;
