'use client';
import Loading from '@/components/atoms/loading';
import dynamic from 'next/dynamic';

export const Header = dynamic(() => import('./header'), {
    // loading: () => <Loading single={{ line: true, height: '50px' }} />,
    ssr: false
});
export const HeaderFull = dynamic(() => import('./header'), {
    loading: () => <Loading header />,
    ssr: false
});

export const CardProduct = dynamic(() => import('@/components/molecules/card-product'), { ssr: false });

export const FooterNav = dynamic(() => import('./footer-nav'), { ssr: false });
export const ReadMore = dynamic(() => import('@/components/molecules/read-more'), { ssr: false });
export const ReadMoreContent = dynamic(() => import('@/components/molecules/read-more-content'), { ssr: false });

export const AddBtnBse = dynamic(() => import('@/components/molecules/add-btn-bse'), { ssr: false });
export const BannerSlider = dynamic(() => import('@/components/organisms/banner-slider'), {
    ssr: false
    // loading: () => <Loading single={{ line: true, height: '200px' }} />
});
export const CompanySlider = dynamic(() => import('@/components/organisms/company-slider'), { ssr: false });
export const BlockBannerImageCarousel = dynamic(() => import('@/components/organisms/block-banner-image-carousel'), { ssr: false });
export const HowToOrder = dynamic(() => import('@/components/templates/content/how-to-order'), { ssr: false });
export const UserBrowserItem = dynamic(() => import('@/components/templates/content/user-browse-item'), { ssr: false });
export const InfiniteBlock = dynamic(() => import('@/components/templates/home-content/infinite-block'), { ssr: false });
export const MeiliSearch = dynamic(() => import('@/components/templates/content/meili-search'), { ssr: false });
export const CopyToClipboardButton = dynamic(() => import('@/components/atoms/copy-clip-board'), { ssr: false });
// export const InfiniteCategoryTaxonomy = dynamic(() => import('@/components/templates/home-content/infinite-category-taxonomy'), {
//     ssr: false
// });
export const MeiliSearchFilters = dynamic(() => import('@/components/templates/content/meili-search-filters'), { ssr: false });

export const LoginLinkModal = dynamic(() => import('@/components/organisms/login-modal').then((module) => module.LoginLinkModal), {
    ssr: false
});
export const Logout = dynamic(() => import('@/components/molecules/logout'), { ssr: false });

export const DeliveryTypeModal = dynamic(() => import('@/components/atoms/delivery-type-modal'), { ssr: false });
export const SanitizeHtmlView = dynamic(() => import('@/components/atoms/sanitize-html-view'), { ssr: false });
export const ProductInfoModal = dynamic(() => import('@/components/molecules/product-info-modal'), { ssr: false });
export const CountdownTimer = dynamic(() => import('@/components/atoms/countdown-timer'), { ssr: false });
export const RecyclerListViewComponent = dynamic(() => import('@/components/templates/content/recycler-list-view'), {
    ssr: false
    // loading: () => <Loading pagesStyle={{ productCardHome: true }} />
});

export const MixPanelBlockAction = dynamic(
    () => import('@/components/molecules/mix-panel-client-tracker').then((module) => module.MixPanelBlockAction),
    { ssr: false }
);
